module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"r9zqkSsAm8HwozkFFSirnvrgGUxJWzLF","devKey":"r9zqkSsAm8HwozkFFSirnvrgGUxJWzL","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"ofg5rnbm4tus","accessToken":"9k3z5cLJO5ELtDVaXJNDCvbi-lNiwYdzWB3suA05foY","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},"siteMetadata":{"title":"Chord Commerce Starter","siteUrl":"https://www.chord.co","description":"A starter kit.","social":{"facebook":"chordcommerce","instagram":"chordcommerce","pinterest":"chordcommerce"}},"authConfig":{"magic":{"apiKey":"pk_live_4188450442FB60BF"}},"segmentConfig":{"prodKey":"r9zqkSsAm8HwozkFFSirnvrgGUxJWzLF","devKey":"r9zqkSsAm8HwozkFFSirnvrgGUxJWzL","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"sUp3rS3cR3t","partialMatching":true,"pagePaths":["/admin"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Open Sans, SF Pro Text"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
